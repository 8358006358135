<template>
    <div class="c-ShareBtn u-relative | u-static@sm" v-bind:class="{ 'is-share-active': active, 'is-right': right }">
        <div class="c-ShareBtn-popin u-absolute">
            <ul class="t-list u-flex u-relative | u-w6of8@sm u-offset-l-w1of8@sm">
                <li><a :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`" target="_blank" rel="noopener noreferrer" class="t-link--primary t-text--sm u-marg-r-sm">Facebook</a></li>
                <li><a :href="`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=&summary=&source=`" target="_blank" rel="noopener noreferrer" class="t-link--primary t-text--sm u-marg-r-sm">Linkedin</a></li>
                <li><a :href="`https://twitter.com/intent/tweet?url=${url}&text=`" target="_blank" rel="noopener noreferrer" class="t-link--primary t-text--sm u-marg-r-sm">Twitter</a></li>
                <li><a :href="`mailto:?&subject=Global Innovation Barometer 2020&body=${url}`" target="_blank" rel="noopener noreferrer" class="t-link--primary t-text--sm">Email</a></li>
            </ul>

            <button v-on:click="onClose" type="button" class="c-ShareBtn-popin-close t-btn u-absolute">
                <div class="c-ShareBtn-popin-close-bg u-fit"></div>
            </button>
        </div>

        <button v-on:click="onBtnClick" type="button" class="c-ShareBtn-trigger t-btn--primary u-flex u-align-items-c">
            <svg class="u-marg-r-xs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"  width="10" height="10">
                <path d="M9.5 8.4c0 .9-.7 1.6-1.5 1.6s-1.6-.7-1.6-1.6c0-.2 0-.3.1-.5L3.2 6.1c-.3.3-.7.5-1.2.5C1.2 6.6.5 5.9.5 5S1.2 3.4 2 3.4c.5 0 .9.2 1.2.5L6.4 2c0-.2-.1-.3-.1-.5C6.4.7 7.1 0 8 0s1.6.7 1.6 1.6S8.8 3.2 8 3.2c-.5 0-.9-.2-1.2-.6L3.5 4.5c0 .2.1.3.1.5s0 .3-.1.5l3.2 1.9c.4-.4.8-.6 1.3-.6.8 0 1.5.7 1.5 1.6z" fill="#0e1d2a"/>
            </svg>
            <span>Share</span>
        </button>

    </div>
</template>

<script>
    var UID = 0;

    export default {
        name: 'ShareBtn',

        props: {
            'right': { type: Boolean, value: false },
        },

        data() {
            return {
                active: false,
                url: window.location.href,
            };
        },

        mounted() {
            UID++;
            this.id = 'share-'+UID;

            this.$eventHub.$on('questionbtn:open', this.onOpen);
        },

        beforeDestroy() {
            this.$eventHub.$off('questionbtn:open', this.onOpen);
        },

        methods: {
            onBtnClick() {
                this.$eventHub.$emit('questionbtn:open', this.id);
            },

            onOpen(target) {
                this.active = (target === this.id);
            },

            onClose() {
                this.active = false;
            },
        }
    }
</script>

<style lang="stylus">
    .c-ShareBtn {
        display inline-block

        &.is-share-active {
            z-index 100
        }

        &-popin {
            top -50px
            left -42px

            padding-top 120px
            padding-left 65px
            padding-right 65px
            padding-bottom 65px

            height auto

            opacity 0
            visibility hidden
            transform translateZ(0)
            will-change opacity, visibility
            transition opacity 0.6s $ease-out-quart, visibility 0.6s $ease-out-quart

            .is-right & {
                left auto
                right -42px

                @media $breakpoints.sm {
                    left 50%
                    transform translateX(-50%)
                    right unset
                }
            }

            .is-share-active & {
                opacity 1
                visibility visible

                @media $breakpoints.sm {
                    z-index 100
                }
            }

            @media $breakpoints.md {
                width calc(70vw - 40px)
            }

            @media $breakpoints.sm {
                columns 1
                width calc(100vw - 36px)
                // left -16.5%
                left 50%
                transform translateX(-50%)
                padding-left 0
                padding-right 0
            }

            &:before {
                content ""
                position absolute
                top 0
                left 0
                width 100%
                height 100%

                background linear-gradient(to bottom, #e8e7e5, #ffffff)
                border-radius 12px

                opacity 0.97

            }

            &-close {
                width 30px
                height 30px

                top 20px
                right 20px

                &:before, &:after {
                    content ""
                    position absolute
                    top 50%
                    left 50%
                    margin-top -1px
                    margin-left -5px
                    width 10px
                    height 2px
                    border-radius 2px
                    background $colors['black'];

                    transform rotate(45deg)
                }

                &:after {
                    transform rotate(-45deg)
                }


                &-bg {
                    border-radius 50%
                    border 1px solid $colors['black'];

                    opacity 0.15


                    transform scale(1) translateZ(0)
                    will-change transform
                    transition transform 0.6s $ease-out-quart
                }

                &:hover {
                    .c-ShareBtn-popin-close-bg {
                        transform scale(1.3) translateZ(0)
                    }
                }
            }
        }

        &-trigger {
            position relative
            font-size 11px

            .is-share-active & {
                &:before {
                    opacity 0
                }

                @media $breakpoints.sm {
                    z-index 101
                }
            }

            span {
                position: relative;
                top: 1px;
            }
        }
    }
</style>