<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import getPrefix from '@/utilities/getPrefix';
    import calculateAspectRatio from '@/utilities/calculateAspectRatio';

    export default {
        name: 'ObjectFit',

        props: {
            width: { type: Number, default: 0},
            height: { type: Number, default: 0},

            widthMobile: { type: Number, default: 0},
            heightMobile: { type: Number, default: 0},

            type: { type: String, default: 'cover' },
        },

        computed: {
            aspect() {
                return (this.type !== 'cover');
            },

            ...mapState('window', {
                'winWidth': 'width',
            }),
        },

        mounted() {
            this.$nextTick(() => {
                this.$objectFit = this.$el.querySelector('.js-object-fit');

                this.$eventHub.$on('resize', this.resize);

                this.resize();
            });
        },

        beforeDestroy() {
            this.$eventHub.$off('resize', this.resize);
        },

        methods: {
            resize() {
                if (!this.$objectFit) return;

                this.parentWidth = this.$el.clientWidth;
                this.parentHeight = this.$el.clientHeight;

                if (this.widthMobile && this.heightMobile && this.winWidth < 767) {
                    this.computedSize = calculateAspectRatio(this.widthMobile, this.heightMobile, this.parentWidth, this.parentHeight, this.aspect);
                } else {
                    this.computedSize = calculateAspectRatio(this.width, this.height, this.parentWidth, this.parentHeight, this.aspect);
                }

                this.offsetX = Math.round((this.parentWidth - this.computedSize.width) * 0.5);
                this.offsetY = Math.round((this.parentHeight - this.computedSize.height) * 0.5);

                // debug
                // console.log(this.$el, this.computedSize, this.offsetX, this.offsetY);

                if (this.parentHeight === 0) {
                    this.offsetY = 0;
                }

                this.$objectFit.style.width = `${Math.round(this.computedSize.width)}px`;
                this.$objectFit.style.height = `${Math.round(this.computedSize.height)}px`;

                this.$objectFit.style[getPrefix('transform')] = `translate(${this.offsetX}px, ${this.offsetY}px)`;
            },
        },
    };
</script>
