<template>
    <div class="c-DefaultSlider" v-bind:class="{ 'is-drag': dragStart }"
        v-on:mousedown="onDragStart"
        v-on:touchstart.passive="onDragStart"
        v-on:mousemove="onDrag"
        v-on:touchmove="onDrag"
        v-on:mouseup="onDragEnd"
        v-on:touchend.passive="onDragEnd"
        v-on:touchleave.passive="onDragEnd"
        v-on:mouseleave="onDragEnd">
        <slot />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import getPrefix from '@/utilities/getPrefix';
    import getOffsets from '@/utilities/getOffsets';

    export default {
        name: 'DefaultSlider',

        props: {
            isMagnetic: { type: Boolean, default: false },
            initOffset: { type: Number, default: 0 },
            securityCulling: { type: Number, default: 40 },
        },

        data() {
            return {
                isDrag: false,
                dragStart: false,
            };
        },

        computed: {
            ...mapState('window', [
                'width',
                'isTouch',
            ]),
        },

        created() {
            this.currentItem = 0;

            this.x = 0;
            this.targetX = 0;

            this.offsetX = [];
            this.enabled = [];
        },

        mounted() {
            this.$nextTick(() => {
                this.$items = Array.from(this.$el.querySelectorAll('.js-item'));
                this.nbItems = this.$items.length;

                this.$items.forEach((el, i) => {
                    this.enabled[i] = false;
                });

                this.$eventHub.$on('resize', this.resize);
                this.$eventHub.$on('update', this.update);

                this.resize();
            });
        },

        beforeDestroy() {
            this.$eventHub.$off('resize', this.resize);
            this.$eventHub.$off('update', this.update);
        },

        methods: {
            resize() {
                if (!this.nbItems) return;

                this.containerWidth = this.$el.offsetWidth;
                this.containerOffsetLeft = getOffsets(this.$el).left;

                this.contentWidth = 0;

                this.$items.forEach((el, i) => {
                    this.itemWidth = el.offsetWidth;
                    this.contentWidth += this.itemWidth;

                    this.offsetX[i] = getOffsets(el).left - this.containerOffsetLeft;
                });

                if (this.initOffset && this.targetX === 0) {
                    this.targetX = - this.initOffset * this.itemWidth + this.containerWidth/2;
                }

                for(this.i = 0; this.i < this.nbItems; this.i++){
                    this.x = this.targetX;
                    this.$items[this.i].style[getPrefix('transform')] = `translate3d(${this.x}px, 0px, 0px)`;
                }

                this.multiplier = (this.isTouch) ? 1.6 : 1; // add speed on touch devices
            },

            onDragStart() {
                this.dragStart = true;
                this.dragStartItem = this.currentItem;

                this.pointerEvent = this.isTouch && event.type !== 'mousedown' ? (event.touches[0] || event.changedTouches[0]) : event;

                this.oldX = this.targetX;
                this.startX = this.pointerEvent.pageX;
            },

            onDrag() {
                if (!this.dragStart) return;

                this.pointerEvent = this.isTouch && event.type !== 'mousemove' ? (event.touches[0] || event.changedTouches[0]) : event;

                if (Math.abs( this.startX - this.pointerEvent.pageX) > 10) {
                    this.isDrag = true;
                }

                // prevent scroll during drag
                if(this.isDrag && event.cancelable) {
                    event.preventDefault();
                    // event.stopPropagation();
                }

                this.targetX = this.oldX + (this.pointerEvent.pageX - this.startX) * this.multiplier;
            },

            onDragEnd() {
                this.dragStart = false;

                if (!this.isDrag) return;

                setTimeout(() => {
                    if (this.isMagnetic) {
                        if((this.dragStartItem === this.currentItem) && -(this.targetX) > this.offsetX[this.currentItem] + 80 ) {
                            this.nextItem();
                        } else if ((this.dragStartItem === this.currentItem) && -(this.targetX) < this.offsetX[this.currentItem] - 80) {
                            this.prevItem();
                        } else {
                            this.targetX = -this.offsetX[this.currentItem];
                        }
                    }

                    this.isDrag = false;
                }, 100);
            },

            update() {
                this.lockAxisX();

                // Smooth Val
                // ---------------
                this.x += Math.round((this.targetX - this.x) * 0.1 * 1000) / 1000;

                // const newPos = this.targetX;
                // const diff = newPos - this.x;
                // this.rotate = diff * 0.01;


                // document.documentElement.style.setProperty('--rotateY', `${speed}deg`);

                for(this.i = 0; this.i < this.nbItems; this.i++){

                    // Culling
                    // ---------------
                    if (((this.x + this.offsetX[this.i] + this.itemWidth + this.securityCulling) < 0
                        || (this.x + this.offsetX[this.i] - this.containerWidth - this.securityCulling) > 0)) {

                        if(this.enabled[this.i]){
                            this.$items[this.i].classList.add('is-disabled');
                            this.enabled[this.i] = false;

                            this.$items[this.i].style[getPrefix('transform')] = `translate3d(${this.x}px, 0px, 0px)`;
                        }
                    } else {
                        if(!this.enabled[this.i]){
                            this.enabled[this.i] = true;
                            this.$items[this.i].classList.remove('is-disabled');
                        }

                        this.$items[this.i].style[getPrefix('transform')] = `translate3d(${this.x}px, 0px, 0px)`;
                        // this.$items[this.i].querySelector('.c-SectionTwo-dataTwo-bar-inner').style[getPrefix('transform')] = `rotateY(${this.rotate}deg)`;
                    }

                    if (this.currentItem !== this.i &&
                        (-this.x > (this.offsetX[this.i] - (this.itemWidth/2) )
                        && -this.x < (this.offsetX[this.i] - (this.itemWidth/2)  + this.itemWidth))) {

                        this.currentItem = this.i;
                    }
                }
            },

            lockAxisX() {
                // lock axis: x
                if (this.targetX < -this.contentWidth + this.containerWidth - this.offsetX[0]) {
                    this.targetX = -this.contentWidth + this.containerWidth - this.offsetX[0];
                }

                if (this.targetX > 0) {
                    this.targetX = 0;
                }
            },

            prevItem() {
                this.targetX = -(this.offsetX[this.currentItem-1] ? this.offsetX[this.currentItem-1] : this.offsetX[0])
            },

            nextItem() {
                this.targetX = -(this.offsetX[this.currentItem+1] ? this.offsetX[this.currentItem+1] : this.offsetX[this.nbItems-1]);
            },

            goToItem(index, noAnim = false) {
                if (this.offsetX[index] === undefined) return;

                this.targetX = this.offsetX[0] - this.offsetX[index];

                if (noAnim) {
                    for(this.i = 0; this.i < this.nbItems; this.i++){
                        this.x = this.targetX;
                        this.$items[this.i].style[getPrefix('transform')] = `translate3d(${this.x}px, 0px, 0px)`;
                    }
                }
            },
        }
    }
</script>

<style lang="stylus">
    .c-DefaultSlider {
        --rotateY: 0;

        cursor grab

        &.is-drag {
            cursor grabbing
        }
    }
</style>