<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import getOffsetTop from '@/utilities/getOffsetTop';

    export default {
        name: 'AppearObject',

        props: {
            active: { type: Boolean, default: true },
            once: { type: Boolean, default: true },
            ratio: { type: Number, default: 0.2 }
        },

        computed: {
            ...mapState('window', [
                'isTouch',
                'height',
            ]),
        },

        created() {
            this.show = false;
        },

        mounted() {
            this.$eventHub.$on('resize', this.resize);
            this.$eventHub.$on('update', this.update);

            this.resize();
        },

        beforeDestroy() {
            this.$eventHub.$off('resize', this.resize);
            this.$eventHub.$off('update', this.update);
        },

        methods: {
            resize() {
                if (this.isTouch) {
                    this.show = true;
                    this.$el.classList.add('is-appear');
                }

                this.offsetY = getOffsetTop(this.$el);

                let trigger = (this.height - (this.height * this.ratio));
                this.trigger = this.offsetY - trigger;
            },

            update() {
                if ((this.once && this.show) || this.isTouch || !this.active) return;

                if (!this.show && this.$root.smoothScroll >= this.trigger) {
                    this.show = true;
                    this.$el.classList.add('is-appear');
                }

                if (!this.once) {
                    if (this.show && this.$root.smoothScroll < this.trigger) {
                        this.show = false;
                        this.$el.classList.remove('is-appear');
                    }
                }
            },
        },
    };
</script>
