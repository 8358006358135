<template>
    <div class="c-QuestionBtn u-relative | u-static@sm" v-bind:class="{ 'is-question-active': active, 'is-right': right }">
        <div class="c-QuestionBtn-popin u-absolute">
            <div class="u-relative | u-w6of8@sm u-offset-l-w1of8@sm">
                <p class="t-text--sm">{{ question }}</p>
                <p class="t-text--sm u-color--gray-dark u-italic | u-marg-t-sm@sm">{{ response }}</p>
            </div>

            <button v-on:click="onClose" type="button" class="c-QuestionBtn-popin-close t-btn u-absolute">
                <div class="c-QuestionBtn-popin-close-bg u-fit"></div>
            </button>
        </div>

        <button v-on:click="onBtnClick" type="button" class="c-QuestionBtn-trigger t-btn--primary u-flex u-align-items-c">
            <svg class="u-marg-r-xs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="10" height="10">
                <path fill="#0e1d2a" d="M12 5.8H7.3l4.3-1.9-.2-.4-4.3 1.8 3.3-3.4-.3-.3-3.4 3.3L8.5.6 8.1.4 6.2 4.7V0h-.4v4.7L3.9.4l-.4.2 1.8 4.3-3.4-3.3-.3.3 3.3 3.4L.6 3.5l-.2.4 4.3 1.9H0v.4h4.7L.4 8.1l.2.4 4.3-1.8-3.3 3.4.3.3 3.4-3.3-1.8 4.3.4.2 1.9-4.3V12h.4V7.3l1.9 4.3.4-.2-1.8-4.3 3.4 3.3.3-.3-3.3-3.4 4.3 1.8.2-.4-4.3-1.9H12z"/>
            </svg>
            <span>Question {{ number }}</span>
        </button>

    </div>
</template>

<script>
    var UID = 0;

    export default {
        name: 'QuestionBtn',

        props: {
            'number': { type: String, value: '00' },
            'question': { type: String, value: '' },
            'response': { type: String, value: '' },
            'right': { type: Boolean, value: false },
        },

        data() {
            return {
                active: false,
            };
        },

        mounted() {
            UID++;
            this.id = UID;

            this.$eventHub.$on('questionbtn:open', this.onOpen);
        },

        beforeDestroy() {
            this.$eventHub.$off('questionbtn:open', this.onOpen);
        },

        methods: {
            onBtnClick() {
                this.$eventHub.$emit('questionbtn:open', this.id);
            },

            onOpen(target) {
                this.active = (target === this.id);
            },

            onClose() {
                this.active = false;
            },
        }
    }
</script>

<style lang="stylus">
    .c-QuestionBtn {
        display inline-block

        &.is-question-active {
            z-index 100
        }

        &-popin {
            top -50px
            left -42px

            padding-top 120px
            padding-left 65px
            padding-right 65px
            padding-bottom 65px

            width calc(50vw - 40px)
            height auto

            columns: 2;
            column-gap: 65px;

            white-space: normal;

            opacity 0
            visibility hidden
            transform translateZ(0)
            will-change opacity, visibility
            transition opacity 0.6s $ease-out-quart, visibility 0.6s $ease-out-quart

            .is-right & {
                left auto
                right -42px

                @media $breakpoints.sm {
                    left 50%
                    transform translateX(-50%)
                    right unset
                }
            }

            .is-question-active & {
                opacity 1
                visibility visible

                @media $breakpoints.sm {
                    z-index 100
                }
            }

            @media $breakpoints.md {
                width calc(70vw - 40px)
            }

            @media $breakpoints.sm {
                columns 1
                width calc(100vw - 36px)
                // left -16.5%
                left 50%
                transform translateX(-50%)
                padding-left 0
                padding-right 0
            }

            &:before {
                content ""
                position absolute
                top 0
                left 0
                width 100%
                height 100%

                background linear-gradient(to bottom, #e8e7e5, #ffffff)
                border-radius 20px

                opacity 0.97

            }

            &-close {
                width 30px
                height 30px

                top 20px
                right 20px

                &:before, &:after {
                    content ""
                    position absolute
                    top 50%
                    left 50%
                    margin-top -1px
                    margin-left -5px
                    width 10px
                    height 2px
                    border-radius 2px
                    background $colors['black'];

                    transform rotate(45deg)
                }

                &:after {
                    transform rotate(-45deg)
                }


                &-bg {
                    border-radius 50%
                    border 1px solid $colors['black'];

                    opacity 0.15


                    transform scale(1) translateZ(0)
                    will-change transform
                    transition transform 0.6s $ease-out-quart
                }

                &:hover {
                    .c-QuestionBtn-popin-close-bg {
                        transform scale(1.3) translateZ(0)
                    }
                }
            }
        }

        &-trigger {
            position relative
            font-size 11px

            .is-question-active & {
                &:before {
                    opacity 0
                }

                @media $breakpoints.sm {
                    z-index 101
                }
            }

            span {
                position: relative;
                top: 1px;
            }
        }
    }
</style>