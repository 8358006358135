<template>
    <div v-bind:class="{ 'is-sticky': isSticky, 'is-dark': isDark }" class="c-SubHeader c-Container u-fixed u-pos-tl u-fit-w u-overflow-h">
        <div class="c-SubHeader-bg u-absolute u-pos-tl u-fit"></div>
        <div class="u-relative u-flex u-align-items-c">
            <router-link v-bind:to="{ name: 'Sections', params: { initIndex: (number-1) } }" class="c-SubHeader-link t-link u-block u-w1of16 u-relative | u-w1of8@sm" aria-label="back button">
                <svg class="c-SubHeader-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 44" width="40" height="44">
                    <path d="M38.1 35.3C34.3 40 28.4 43 21.8 43c-11.6 0-21-9.4-21-21s9.4-21 21-21c6.6 0 12.4 3 16.3 7.7" opacity=".15" fill="none" stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10"/>
                    <path class="c-SubHeader-icon-line" d="M38.1 35.3C34.3 40 28.4 43 21.8 43c-11.6 0-21-9.4-21-21s9.4-21 21-21c6.6 0 12.4 3 16.3 7.7" fill="none" stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10"/>
                    <path d="M40 21.2l-20.4-.1 4.7-4.7-1.1-1-6.5 6.4 6.7 6.7 1.1-1.1-4.9-4.8 20.4.1v-1.5z" fill="#ffffff"/>
                </svg>

                <svg class="c-SubHeader-icon c-SubHeader-icon--dark u-absolute u-pos-tl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 44" width="40" height="44">
                    <path d="M38.1 35.3C34.3 40 28.4 43 21.8 43c-11.6 0-21-9.4-21-21s9.4-21 21-21c6.6 0 12.4 3 16.3 7.7" opacity=".15" fill="none" stroke="#0e1d2a" stroke-width="1.5" stroke-miterlimit="10"/>
                    <path class="c-SubHeader-icon-line" d="M38.1 35.3C34.3 40 28.4 43 21.8 43c-11.6 0-21-9.4-21-21s9.4-21 21-21c6.6 0 12.4 3 16.3 7.7" fill="none" stroke="#0e1d2a" stroke-width="1.5" stroke-miterlimit="10"/>
                    <path d="M40 21.2l-20.4-.1 4.7-4.7-1.1-1-6.5 6.4 6.7 6.7 1.1-1.1-4.9-4.8 20.4.1v-1.5z" fill="#0e1d2a"/>
                </svg>
            </router-link>
            <div class="c-SubHeader-content u-w10of16 u-offset-l-w1of16 | u-w10of16@sm u-offset-l-w1of16@sm">
                <span class="c-SubHeader-content-label t-text--xxs u-uppercase u-font-bold u-color--black">Section 0{{number}}<span class="u-marg-x-sm | u-hide@sm">•</span><span class="u-block@sm">{{title}}</span></span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import getPrefix from '@/utilities/getPrefix';
    import getOffsetTop from '@/utilities/getOffsetTop';

    export default {
        name: 'SubHeader',

        props: {
            active: { type: Boolean, default: false },
            number: { type: Number, default: 1 },
            title: { type: String, default: '' },
        },

        data() {
            return {
                isSticky: false,
                isDark: false,
            };
        },

        computed: {
            ...mapState('window', [
                'minHeight',
            ]),
        },

        mounted() {
            this.$eventHub.$on('resize', this.resize);
            this.$eventHub.$on('update', this.update);

            this.resize();
        },

        beforeDestroy() {
            this.$eventHub.$off('resize', this.resize);
            this.$eventHub.$off('update', this.update);
        },

        methods: {
            resize() {
                this.offsetTop = getOffsetTop(this.$el);
                this.height = this.$el.offsetHeight + this.offsetTop;
                this.halfHeight = this.$el.offsetHeight / 2;

                this.top = parseInt(getComputedStyle(this.$el).getPropertyValue('top'));
            },

            update() {
                if(!this.active) return;

                if (this.$root.smoothScroll > this.oldScrollTop && this.$root.smoothScroll > this.minHeight) {
                    if (this.direction !== 1) {
                        this.direction = 1;

                        this.currentScroll = this.$root.smoothScroll
                        this.currentY = this.height - this.y;
                    }

                    if (this.$root.smoothScroll <= this.currentScroll + this.height) {
                        this.y = -(this.currentY - ((this.currentScroll + this.height) - this.$root.smoothScroll));
                    } else {
                        this.y = -this.height;
                    }
                }
                else if (this.$root.smoothScroll != this.oldScrollTop) {
                    if(this.direction !== -1) {
                        this.direction = -1;

                        this.currentScroll = this.$root.smoothScroll
                        this.currentY = this.height + this.y;
                    }

                    if (this.$root.smoothScroll > this.currentScroll - this.height && this.y < -this.top) {
                        this.tempY = (this.currentScroll - this.height) - this.$root.smoothScroll + this.currentY;

                        if ( this.tempY < -this.top) {
                            this.y = this.tempY;
                        } else {
                            this.y = -this.top
                        }
                    } else {
                        if (this.$root.smoothScroll <= this.minHeight) {
                            this.y = 0;
                        } else if (this.$root.smoothScroll <= this.minHeight + this.top ) {
                            this.y = this.minHeight - this.$root.smoothScroll;
                        } else {
                            this.y = -this.top;
                        }
                    }
                }

                this.isDark = (this.$root.smoothScroll > this.minHeight - this.halfHeight - this.top);
                this.isSticky = (this.$root.smoothScroll > this.minHeight + this.top);


                this.$el.style[getPrefix('transform')] = `translate3d(0px, ${this.y}px, 0px)`;

                this.oldScrollTop = this.$root.smoothScroll;

            }
        },
    }
</script>

<style lang="stylus">
    .c-SubHeader {
        z-index 997
        top 120px

        padding-top 10px
        padding-bottom 10px
        height 70px
        overflow hidden

        opacity 0
        transform translateZ(0)
        transition opacity 0.8s $ease-out-quart
        will-change opacity

        @media $breakpoints.sm {
            top 95px
            padding-top 18px
            padding-bottom 18px
            height 90px
        }

        .is-page-ready & {
            opacity 1
        }


        &-bg {
            background white
            background linear-gradient(to bottom, #e8e7e5, #ffffff)
            box-shadow: 0px 1px 0px 0 white

            opacity 0
            transform translateZ(0)
            will-change opacity

            .is-dark & {
                transition opacity 0.4s $ease-out-quart
            }

            .is-sticky & {
                opacity 0.97
            }
        }

        &-icon {
            display block

            opacity 1
            transform translateZ(0)
            will-change opacity
            transition opacity 0.8s $ease-out-quart

            @media $breakpoints.sm {
                opacity 0
            }

            .is-dark & {
                opacity 0
            }

            &--dark {
                opacity 0
                transform translateZ(0)
                will-change opacity
                transition opacity 0.8s $ease-out-quart

                .is-dark & {
                    opacity 1
                }
            }

            &-line {
                stroke-dasharray: 103;
                stroke-dashoffset: 103;

                transition stroke-dashoffset 0.6s $ease-out-quart
                transform translateZ(0)
                will-change stroke-dashoffset
            }
        }

        &-content {
            opacity 0
            transform translateZ(0)
            will-change opacity

            .is-dark & {
                opacity 1
                transition opacity 0.6s $ease-out-quart
            }

            &-label {
                @media $breakpoints.sm {
                    letter-spacing 0.05em
                }
            }
        }

        &-link {
            &:hover {
                .c-SubHeader-icon-line {
                    stroke-dashoffset: 206;
                }
            }
        }
    }
</style>
